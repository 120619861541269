var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "CAlert",
        {
          staticClass: "mb-4",
          attrs: {
            show: _vm.delaySuccessAlert,
            closeButton: "",
            color: "success",
          },
          on: {
            "update:show": function ($event) {
              _vm.delaySuccessAlert = $event
            },
          },
        },
        [
          _c("strong", [
            _vm._v(_vm._s(_vm.successMessage.itemName) + " "),
            _c("span", { staticStyle: { "font-weight": "400" } }, [
              _vm._v(_vm._s(_vm.successMessage.message)),
            ]),
          ]),
          _c("CProgress", {
            attrs: {
              color: "success",
              max: _vm.delaySecs,
              value: _vm.delaySuccessAlert,
              height: "2px",
            },
          }),
        ],
        1
      ),
      _c(
        "CAlert",
        {
          staticClass: "mb-4",
          attrs: { show: _vm.isShowError, closeButton: "", color: "danger" },
          on: {
            "update:show": function ($event) {
              _vm.isShowError = $event
            },
          },
        },
        _vm._l(_vm.errorMessage, function (object, name, index) {
          return _c("div", { key: index }, [
            _c("strong", [_vm._v(_vm._s(_vm._f("normalizeSentence")(name)))]),
            _c(
              "ul",
              { attrs: { id: "v-for-object" } },
              [
                !Array.isArray(object)
                  ? [_c("li", [_vm._v(_vm._s(object))])]
                  : _vm._l(object, function (item, index) {
                      return _c("li", { key: index }, [
                        _vm._v(" " + _vm._s(item) + " "),
                      ])
                    }),
              ],
              2
            ),
          ])
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }