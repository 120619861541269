import Vue from "vue";
import Vuex from "vuex";
import auth from "./auth";
import restaurants from "./restaurants";

Vue.use(Vuex);

const state = {
  sidebarShow: "responsive",
  sidebarMinimize: false,
  asideShow: false,
  darkMode: false,
  deviceSoftware: true,

  menuGroupsAsideShow: false,
  restaurantsAsideShow: false,
  menuSjabloonMakeLinkAsideShow: false,
  labelActivityLogsAsideShow: false,
  taskActivityLogsAsideShow: false,
  orderActivityLogsAsideShow: false,
  latestRestaurantActivitiesAsideShow: false,
  editTaskAsideShow: false,
  invoicePaymentsAsideShow: false,
  newImageAsideShow: false,
  selectNewImageAsideShow: false,
  assignImageToCategoryAsideShow: false,
  assignHeroImageToRestaurantAsideShow: false,
  changeHeroImageAsideShow: false,
  changeLogoAsideShow: false,
  sendRestaurantDetailsAsideShow: false,
  holidayItemAsideShow: false,

  // Filters
  filterShow: true,
  filters: {
    selectedRestaurant: null,

    users: {
      selectedLanguages: [],
      selectedType: null,
    },
    restaurants: {
      linkedMenus: {
        selectedCategory: null,
      },
    },
    orders: {
      search: null,
      selectedOrderStatus: "today",
      selectedOrderFeatureStatus: null,
      orderFeatureStatusCondition: "AND",
      selectedOrderChannel: null,
      selectedReferrerParams: null,
      selectedPaymentMethod: null,
      deliveryServiceStatus: null,
      selectedRestaurant: null,
      selectedUser: null,
      startDate: null,
      endDate: null,
    },
    invoices: {
      startDate: null,
      endDate: null,
    },
    txn: {
      startDate: null,
      endDate: null,
    },
    activities: {
      logName: null,
      startDate: null,
      endDate: null,
      causerType: null,
      selectedCauser: null,
      selectedSubject: null,
    },
    delivery_zones: {
      selectedZips: [],
    },
    menutepay: {
      selectedCompany: null,
    },
    task: {
      assigned: null,
      selectedAdmin: null,
      selectedRestaurant: null,
      status: [],
    },
    images: {
      type: "Category",
      tags: [],
    },
    vouchers: {
      selectedRestaurant: null,
      selectedUser: null,
    },

    country: parseInt(localStorage.getItem("filters-country")) || null,
  },
  reports: {
    revenues: {
      orderChannels: [],
      paymentMethods: [],
      referers: [],
      restaurant: null,
    },
  },

  createPaymentShow: false,
  productPricesShow: false,
  restaurantCategoryShow: false,
  addCustomTimes: false,
  transferOrRevokeOrderShow: false,
  updateCourierPriorityModalShow: false,
  bulkMenuUpdateShow: false,

  // CRM
  crm: {
    hasUnreadMail: false,
    hasPendingTask: false,
  },

  // Default Country
  countryId: parseInt(localStorage.getItem("country")) || 1, // 1 = Belgium, 2 = Netherlands

  // ***
  errors: [],
};

const mutations = {
  toggleSidebarDesktop(state) {
    const sidebarOpened = [true, "responsive"].includes(state.sidebarShow);
    state.sidebarShow = sidebarOpened ? false : "responsive";
  },
  toggleSidebarMobile(state) {
    const sidebarClosed = [false, "responsive"].includes(state.sidebarShow);
    state.sidebarShow = sidebarClosed ? true : "responsive";
  },
  set(state, [variable, value]) {
    state[variable] = value;
  },
  toggle(state, variable) {
    state[variable] = !state[variable];
  },
  setCountry(state, country) {
    state.countryId = country;
    localStorage.setItem("country", country);
    state.filters.country = country;
    localStorage.setItem("filters-country", country);
  },
  setCountryFilter(state, country) {
    state.filters.country = country;
    if (country === null) {
      localStorage.removeItem("filters-country");
      return;
    }
    localStorage.setItem("filters-country", country);
  },

  // ***
  setErrors(state, errors) {
    state.errors = errors;
  },
};

// ***
const getters = {
  errors: (state) => state.errors,
};

export default new Vuex.Store({
  state,
  mutations,
  getters,
  actions: {},
  modules: {
    auth,
    restaurants,
  },
});
