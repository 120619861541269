var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "CElementCover",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.show,
          expression: "show",
        },
      ],
      staticClass: "blocker",
      attrs: { opacity: _vm.opacity, center: _vm.center },
    },
    [
      _c(
        "div",
        {
          staticClass: "d-flex justify-content-center flex-row",
          style: !_vm.center ? "margin-top: " + _vm.mtop : "",
        },
        [
          !_vm.hideText
            ? _c("h3", { staticClass: "mr-3" }, [_vm._v(_vm._s(_vm.text))])
            : _vm._e(),
          _c("div", { staticClass: "sk-wave" }, [
            _c("div", { staticClass: "sk-wave-rect" }),
            _c("div", { staticClass: "sk-wave-rect" }),
            _c("div", { staticClass: "sk-wave-rect" }),
            _c("div", { staticClass: "sk-wave-rect" }),
            _c("div", { staticClass: "sk-wave-rect" }),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }