var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("img", {
    attrs: { src: _vm.src, width: _vm.height, height: _vm.height },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }