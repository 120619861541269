const HOST = process.env.VUE_APP_BACKEND_HOST;
const baseURL = `${HOST}/api`;

const AUTH = {
  LOGIN: `${baseURL}/login`,
  LOGOUT: `${baseURL}/logout`,
  PROFILE: `${baseURL}/me`,
  VERIFY_OTP: `${baseURL}/verify-otp`,
};

const MEMO = {
  GET_ALL: `${baseURL}/me/memos`,
  ADD: `${baseURL}/me/memos`,
  UPDATE: `${baseURL}/me/memos/{id}`,
  DELETE: `${baseURL}/me/memos/{id}`,
};

const REPORTS = {
  ORDER: `${baseURL}/reports/order`,
  REVENUE: `${baseURL}/reports/revenue`,
  REVENUE_BY_ORIGIN_COUNTRY_LAST_MONTH: `${baseURL}/reports/revenue-by-origin-country-last-month`,
  REVENUE_DETAILS_ORDER_CHANNEL: `${baseURL}/reports/revenue/details/order-channel`,
  REVENUE_DETAILS_PAYMENT_METHOD: `${baseURL}/reports/revenue/details/payment-method`,
  REVENUE_DETAILS_REFERER: `${baseURL}/reports/revenue/details/referer`,
};

const SETTINGS = {
  GET_LANGUAGES: `${baseURL}/settings/languages`,
  GET_DEFAULT_COMMISSION: `${baseURL}/settings/get-default-commission`,
};

const USERS = {
  GET_ALL: `${baseURL}/users`,
  GET_ADMIN_USERS: `${baseURL}/users/admin`,
  VIEW: `${baseURL}/users/{id}/view`,
  SHOW: `${baseURL}/users/{id}`,
  UPDATE: `${baseURL}/users/{id}`,
};

const PERMISSIONS = {
  LIST: `${baseURL}/permissions/list`,
};

const ORDERS = {
  GET_ALL: `${baseURL}/orders`,
  SHOW: `${baseURL}/orders/{id}`,
  ADD_NOTE: `${baseURL}/orders/{id}/add-note`,
  CONFIRM: `${baseURL}/orders/{id}/confirm`,
  TAKE_CONTROL: `${baseURL}/orders/{id}/take-control`,
  UPDATE_DELIVERY_SERVICE: `${baseURL}/orders/{id}/update-delivery-service`,
  DELETE: `${baseURL}/orders/{id}`,
};

const MY_RESTAURANT = {
  SHOW_BY_TOKEN: `${baseURL}/restaurant/restaurant/show`,
  UPDATE_BY_TOKEN: `${baseURL}/restaurant/restaurant/update`,
};

const RESTAURANT = {
  GET_ALL: `${baseURL}/restaurants`,
  SHOW: `${baseURL}/restaurants/{id}`,
  UPDATE: `${baseURL}/restaurants/{id}`,
  STORE: `${baseURL}/restaurants`,
  STORE_WIZARD: `${baseURL}/restaurants/wizard`,
  GET_ALL_NAME: `${baseURL}/restaurants/list`,
  GET_ALL_BY_CATEGORY_NAMES: `${baseURL}/restaurants/by-category-names`,
  GET_ALL_BY_TYPES: `${baseURL}/restaurants/by-types`,
  GET_ALL_LOCATIONS: `${baseURL}/restaurants/locations`,
  GET_NAME: `${baseURL}/restaurants/get-name/{id}`,

  SAVE_LOGO: `${baseURL}/restaurants/save-logo`,
  REMOVE_LOGO_FROM_CDN: `${baseURL}/restaurants/{id}/remove-logo`,
  LINK_DEVICE: `${baseURL}/restaurants/link-device`,
  UNLINK_DEVICE: `${baseURL}/restaurants/unlink-device`,
  REPLICATE: `${baseURL}/restaurants/replicate`,
  COPY_FEATURES: `${baseURL}/restaurants/copy-features`,
  VAT_VALIDATION: `${baseURL}/restaurants/vat-validation`,
  UPLOAD_FILE: `${baseURL}/restaurants/upload-file`,

  DOWNLOAD_FILES: `${baseURL}/restaurants/{id}/download/{file_type}`,
  ACCOUNT_BALANCE: `${baseURL}/restaurants/{id}/account-balance`,
  SEND_MAIL_TO_SIGN_CONTRACT: `${baseURL}/restaurants/{id}/send-contract-to-sign`,
  SEND_LOGIN_DETAILS: `${baseURL}/restaurants/{id}/send-login-info`,
  SEND_MAGICSOFTWARE_LINK: `${baseURL}/restaurants/{id}/send-magicsoftware-link`,
  ASSIGN_HERO_IMAGE: `${baseURL}/restaurants/assign-hero-image`,

  PERMISSIONS: `${baseURL}/restaurants/{id}/permissions`,

  // Restaurant Settings
  UPDATE_SETTINGS: `${baseURL}/restaurants/{id}/settings`,
  PUBLISH: `${baseURL}/restaurants/{id}/publish`,

  OPENING_TIMES: {
    GET_ALL: `${baseURL}/restaurants/{restaurant_id}/opening-times`,
    STORE: `${baseURL}/restaurants/{restaurant_id}/opening-times`,
    UPDATE: `${baseURL}/restaurants/{restaurant_id}/opening-times/{id}`,
    DELETE: `${baseURL}/restaurants/{restaurant_id}/opening-times/{id}`,
  },
};

const RESTAURANT_LINKED_TYPES = {
  GET_ALL: `${baseURL}/restaurants/{restaurant_id}/types`,
  STORE: `${baseURL}/restaurants/{restaurant_id}/types`,
  DELETE: `${baseURL}/restaurants/{restaurant_id}/types/{id}`,
  STORE_NEW_INDEXES: `${baseURL}/restaurants/{restaurant_id}/types/store-new-indexes`,
  UNLINKED_RESTAURANT_TYPES: `${baseURL}/restaurants/{restaurant_id}/types/unlinked-restaurant-types`,
  RE_INDEX: `${baseURL}/restaurants/{restaurant_id}/types/re-index`,
};

const RESTAURANT_LINKED_ZIPCODES = {
  GET_ALL: `${baseURL}/restaurants/{restaurant_id}/zipcodes`,
  STORE: `${baseURL}/restaurants/{restaurant_id}/zipcodes`,
  DELETE: `${baseURL}/restaurants/{restaurant_id}/zipcodes/{id}`,
  UNLINKED_ZIPCODES: `${baseURL}/restaurants/{restaurant_id}/zipcodes/unlinked-zipcodes`,
  UPDATE_AVAILABILITY: `${baseURL}/restaurants/{restaurant_id}/zipcodes/{id}/update-availability`,
};

const RESTAURANT_LINKED_CATEGORIES = {
  GET_ALL: `${baseURL}/restaurants/{restaurant_id}/categories`,
  GET_ALL_BY_RESTAURANT_ID: `${baseURL}/restaurants/{restaurant_id}/categories/list`,
  GET_ALL_BY_RESTAURANT_ID_WITH_MENUS: `${baseURL}/restaurants/{restaurant_id}/categories/list-with-menus`,
  SHOW: `${baseURL}/restaurants/{restaurant_id}/categories/{id}`,
  UPDATE: `${baseURL}/restaurants/{restaurant_id}/categories/{id}`,
  STORE: `${baseURL}/restaurants/{restaurant_id}/categories`,
  DELETE: `${baseURL}/restaurants/{restaurant_id}/categories/{id}`,
  STORE_NEW_INDEXES: `${baseURL}/restaurants/{restaurant_id}/categories/store-new-indexes`,
  RE_INDEX: `${baseURL}/restaurants/{restaurant_id}/categories/re-index`,
  REPLICATE: `${baseURL}/restaurants/{restaurant_id}/categories/replicate`,
};

const RESTAURANT_LINKED_MENUS = {
  GET_ALL: `${baseURL}/restaurants/{restaurant_id}/menu`,
  LIST: `${baseURL}/restaurants/{restaurant_id}/menu/list`,
  SHOW: `${baseURL}/restaurants/{restaurant_id}/menu/{id}`,
  UPDATE: `${baseURL}/restaurants/{restaurant_id}/menu/{id}`,
  STORE: `${baseURL}/restaurants/{restaurant_id}/menu`,
  DELETE: `${baseURL}/restaurants/{restaurant_id}/menu/{id}`,

  UPDATE_PRICE: `${baseURL}/restaurants/{restaurant_id}/menu/{id}/update-price`,
  UPDATE_CUSTOM_ID: `${baseURL}/restaurants/{restaurant_id}/menu/{id}/update-custom-id`,
  SAVE_MENU_IMAGE: `${baseURL}/restaurants/{restaurant_id}/menu/save-image`,
  STORE_NEW_INDEXES: `${baseURL}/restaurants/{restaurant_id}/menu/store-new-indexes`,
  RE_INDEX: `${baseURL}/restaurants/{restaurant_id}/menu/re-index`,

  BULK: {
    UPDATE_PRICES: `${baseURL}/restaurants/{restaurant_id}/menu/bulk/update-prices`,
  }
};

const RESTAURANT_TYPES = {
  GET_ALL: `${baseURL}/restaurants/types`,
  LIST: `${baseURL}/restaurants/types/list`,
  SHOW: `${baseURL}/restaurants/types/{id}`,
  UPDATE: `${baseURL}/restaurants/types/{id}`,
  DELETE: `${baseURL}/restaurants/types/{id}`,
  STORE: `${baseURL}/restaurants/types`,
  SAVE_TYPE_IMAGE: `${baseURL}/restaurants/types/save-image`,
};

const REVIEWS = {
  GET_ALL: `${baseURL}/reviews`,
  UPDATE: `${baseURL}/reviews/{id}`,
  DELETE: `${baseURL}/reviews/{id}`,
};

const MENU_GROUPS = {
  GET_ALL: `${baseURL}/menu-groups`,
  SHOW: `${baseURL}/menu-groups/{id}`,
  UPDATE: `${baseURL}/menu-groups/{id}`,
  DELETE: `${baseURL}/menu-groups/{id}`,
  STORE: `${baseURL}/menu-groups`,
  STORE_NEW_INDEXES: `${baseURL}/menu-groups/store-new-indexes`,
  GET_ALL_WITH_CHILDS: `${baseURL}/menu-groups/with-childs`,
  GET_ALL_WITH_CHILD_LIST: `${baseURL}/menu-groups/with-child-list`,
  REPLICATE: `${baseURL}/menu-groups/replicate`,
};

const MENU_GROUP_CHILDS = {
  GET_ALL: `${baseURL}/menu-groups/{mnuExtGrpId}/childs`,
  SHOW: `${baseURL}/menu-groups/{mnuExtGrpId}/childs/{id}`,
  UPDATE: `${baseURL}/menu-groups/{mnuExtGrpId}/childs/{id}`,
  DELETE: `${baseURL}/menu-groups/{mnuExtGrpId}/childs/{id}`,
  STORE: `${baseURL}/menu-groups/{mnuExtGrpId}/childs`,
  STORE_MULTIPLE: `${baseURL}/menu-groups/{mnuExtGrpId}/multiple_childs`,
  STORE_NEW_INDEXES: `${baseURL}/menu-groups/{mnuExtGrpId}/childs/store-new-indexes`,
};

const MENU_SJABLOONS = {
  GET_ALL: `${baseURL}/menu-sjabloons`,
  SHOW: `${baseURL}/menu-sjabloons/{id}`,
  UPDATE: `${baseURL}/menu-sjabloons/{id}`,
  DELETE: `${baseURL}/menu-sjabloons/{id}`,
  STORE: `${baseURL}/menu-sjabloons`,
  REPLICATE: `${baseURL}/menu-sjabloons/replicate`,
  LINK: `${baseURL}/menu-sjabloons/link`,
};

const DEVICE = {
  GET_ALL: `${baseURL}/devices`,
  AVAILABLE_DEVICES: `${baseURL}/devices/available`,
  SHOW: `${baseURL}/devices/{id}`,
  UPDATE: `${baseURL}/devices/{id}`,
  DELETE: `${baseURL}/devices/{id}`,
  STORE: `${baseURL}/devices`,
};

const ZIP_CODES = {
  GET_ALL: `${baseURL}/zip-codes`,
  NEAR_BY: `${baseURL}/zip-codes/near-by`,
  STORE_ZIP_CODE_BOUNDS: `${baseURL}/zip-codes/{zip_code_id}/bounds`,
  SEARCH: `${baseURL}/zip-codes/search`,
};

const INVOICES = {
  GET_ALL: `${baseURL}/invoices`,
  VIEW: `${baseURL}/invoices/view/{id}`,
  SEND: `${baseURL}/invoices/send/{id}`,
  SEND_ALL_UNMAILED: `${baseURL}/invoices/send-all-unmailed`,
  TRANSFER: `${baseURL}/invoices/transfer`,
  MAKE: `${baseURL}/invoices/make`,
  DOWNLOAD_ALL: `${baseURL}/invoices/download`,
};

const PAYMENTS = {
  GET_ALL: `${baseURL}/payments`,
  STORE: `${baseURL}/payments`,
  STORE_MULTIPLE: `${baseURL}/payments/store-multiple`,
  DELETE: `${baseURL}/payments/{id}`,
};

const PRICES = {
  STORE: `${baseURL}/restaurants/{restaurant_id}/prices`,
  UPDATE: `${baseURL}/restaurants/{restaurant_id}/prices/{id}`,
  DELETE: `${baseURL}/restaurants/{restaurant_id}/prices/{id}`,
};

const ACTIVITIES = {
  GET_ALL: `${baseURL}/activities`,
  GET_BY_PROPERTY: `${baseURL}/activities/get-by-property`,
  GET_LATEST_ACTIVITIES: `${baseURL}/activities/get-latest-activities`,
};

const ISSUE = {
  GET: `${baseURL}/issue`,
};

const CRM = {
  GET_COUNT: `${baseURL}/crm/get-count`,
};

const CRM_EMAILS = {
  GET_ALL: `${baseURL}/crm/email`,
  SHOW: `${baseURL}/crm/email/{id}`,
  SEND_EMAIL: `${baseURL}/crm/email/send`,
  UPDATE_EMAIL: `${baseURL}/crm/email/{id}`,
  EMPTY_TRASH: `${baseURL}/crm/email/empty-trash`,
};

const CRM_TASKS = {
  GET_ALL: `${baseURL}/crm/task`,
  GET_MY: `${baseURL}/crm/task/my`,
  UPDATE: `${baseURL}/crm/task/{id}`,
  DELETE: `${baseURL}/crm/task/{id}`,
};

const CRM_MESSAGES = {
  GET_ALL: `${baseURL}/crm/message`,
  SEND: `${baseURL}/crm/message/send`,
  DELETE: `${baseURL}/crm/message/{id}`,
};

const PUSHER = {
  APP_LIST: `${baseURL}/crm/push-app/list`,
  // ONESIGNAL_SUBSCRIPTION: `${baseURL}/crm/onesignal/subscription`,
  FCM_SUBSCRIPTION: `${baseURL}/crm/fcm/subscription`,
};

const COURIER = {
  HUBS: {
    GET_ALL: `${baseURL}/courier/hubs`,
    GET_LIST: `${baseURL}/courier/hubs/list`,
    STORE: `${baseURL}/courier/hubs`,
    SHOW: `${baseURL}/courier/hubs/{id}`,
    UPDATE: `${baseURL}/courier/hubs/{id}`,
    DELETE: `${baseURL}/courier/hubs/{id}`,
  },
  DELIVERY_SERVICE: {
    GET_ALL: `${baseURL}/courier/delivery-service`,
    STORE: `${baseURL}/courier/delivery-service`,
    DELETE: `${baseURL}/courier/delivery-service/{id}`,
    RESTAURANTS_WITHOUT_DS: `${baseURL}/courier/delivery-service/restaurants-without-ds`,
  },
  COURIERS: {
    GET_ALL: `${baseURL}/courier/couriers`,
    GET_LIST: `${baseURL}/courier/couriers/list`,
    BASIC_LIST: `${baseURL}/courier/couriers/basic-list`,
    STORE: `${baseURL}/courier/couriers`,
    SHOW: `${baseURL}/courier/couriers/{id}`,
    UPDATE: `${baseURL}/courier/couriers/{id}`,
    DELETE: `${baseURL}/courier/couriers/{id}`,
  },
  ORDERS: {
    UPDATE_ACTIONS: `${baseURL}/courier/orders/update`,
    TRANSFER: `${baseURL}/courier/orders/transfer`,
    REVOKE: `${baseURL}/courier/orders/revoke`,
  },
  BREAKS: {
    FORCE_END: `${baseURL}/courier/{id}/breaks/force-end`,
  },
};

const MENUTEPAY = {
  COMPANIES: {
    GET_ALL: `${baseURL}/menutepay/companies`,
    GET_LIST: `${baseURL}/menutepay/companies/list`,
    USERS: `${baseURL}/menutepay/companies/{id}/users`,
    RESEND_ACTIVATION_EMAIL: `${baseURL}/menutepay/companies/{id}/users/{user_id}/resend-activation`,

    SHOW: `${baseURL}/menutepay/companies/{id}`,
    STORE: `${baseURL}/menutepay/companies`,
    UPDATE: `${baseURL}/menutepay/companies/{id}`,
    VAT_VALIDATION: `${baseURL}/menutepay/companies/vat-validation`,
  },

  INVOICES: {
    GET_ALL: `${baseURL}/menutepay/invoices`,
    VIEW: `${baseURL}/menutepay/invoices/view/{id}`,
    SEND: `${baseURL}/menutepay/invoices/send/{id}`,
    SEND_ALL_UNMAILED: `${baseURL}/menutepay/invoices/send-all-unmailed`,
    MAKE: `${baseURL}/menutepay/invoices/make`,
    DOWNLOAD_ALL: `${baseURL}/menutepay/invoices/download`,
  },

  PAYMENTS: {
    GET_ALL: `${baseURL}/menutepay/payments`,
    STORE: `${baseURL}/menutepay/payments`,
    DELETE: `${baseURL}/menutepay/payments/{id}`,
  },

  USERS: {
    VERIFY_TOKEN: `${baseURL}/menutepay/users/verify`,
  },
};

const BANK_TRANSACTIONS = {
  GET_ALL: `${baseURL}/bank-transactions`,
  DOWNLOAD: `${baseURL}/bank-transactions/download/{file_name}`,
};

const IMAGES = {
  GET_ALL: `${baseURL}/images`,
  UPLOAD: `${baseURL}/images/upload`,
  STORE: `${baseURL}/images`,
  DELETE: `${baseURL}/images/{id}`,
  TAGS: `${baseURL}/images/tags`,
};

const CATEGORIES = {
  UNIQUE_NAMES: `${baseURL}/categories/unique-names`,
  ASSIGN_BULK_IMAGE: `${baseURL}/categories/assign-bulk-image`,
};

const CAMPAIGNS = {
  GET_ALL: `${baseURL}/campaigns`,
  MAIL_CONTENT: `${baseURL}/campaigns/mail-content`,
};

const VOUCHERS = {
  GET_ALL: `${baseURL}/vouchers`,
  LIST: `${baseURL}/vouchers/list`,
  SHOW: `${baseURL}/vouchers/{id}`,
  STORE: `${baseURL}/vouchers`,
  UPDATE: `${baseURL}/vouchers/{id}`,
  DELETE: `${baseURL}/vouchers/{id}`,
};

const HOLIDAYS = {
  GET_ALL: `${baseURL}/holidays`,
  STORE: `${baseURL}/holidays`,
  UPDATE: `${baseURL}/holidays/{id}`,
  DELETE: `${baseURL}/holidays/{id}`,
};

export default {
  AUTH,
  MEMO,
  HOST,
  REPORTS,
  SETTINGS,
  USERS,
  PERMISSIONS,
  ORDERS,
  MY_RESTAURANT,
  RESTAURANT,
  RESTAURANT_LINKED_TYPES,
  RESTAURANT_LINKED_ZIPCODES,
  RESTAURANT_LINKED_CATEGORIES,
  RESTAURANT_LINKED_MENUS,
  RESTAURANT_TYPES,
  REVIEWS,
  MENU_GROUPS,
  MENU_GROUP_CHILDS,
  MENU_SJABLOONS,
  DEVICE,
  ZIP_CODES,
  INVOICES,
  PAYMENTS,
  PRICES,
  ACTIVITIES,
  ISSUE,
  CRM,
  CRM_EMAILS,
  CRM_TASKS,
  CRM_MESSAGES,
  PUSHER,
  COURIER,
  MENUTEPAY,
  BANK_TRANSACTIONS,
  IMAGES,
  CATEGORIES,
  CAMPAIGNS,
  VOUCHERS,
  HOLIDAYS,

  baseURL,
};
